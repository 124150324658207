import React from 'react';
import {Select as SelectMD} from "@react-md/form";

import './select.scss';

const Select = ({
  label,
  name,
  ...props
}) => (
  <div className="select-form-field">
    <label className="select-form-field__label" htmlFor={name}>
      {label}
    </label>
    <SelectMD
      {...props}
      valueKey="value"
    />
  </div>
);

export default Select;
