// import ApolloClient from 'apollo-boost';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import React from 'react';
import { createUploadLink } from 'apollo-upload-client';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { getUserToken } from 'services/storage';
import { ConnectedRouter } from 'connected-react-router'
import configureStore, { history } from 'stores/store'
import App from 'components/app';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './index.scss';

const token = getUserToken();

const { REACT_APP_API_PATH } = process.env;

const httpLink = createUploadLink({
  uri: `${REACT_APP_API_PATH}/graphql`,
  headers: {
    Authorization: token ? `Bearer ${token}` : ''
  }
})


const target = document.querySelector('#root');
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: httpLink,
});

render(
  <ApolloProvider client={client}>
    <Provider store={configureStore()}>
      <ConnectedRouter history={history}>
        <App />
        <ToastContainer />
      </ConnectedRouter>
    </Provider>
  </ApolloProvider>,
  target
);
