import * as yup from 'yup';

/**
 * Schema validator for products
 *  Notes:
 *  - Not all entries are required (due to backwards compatibility)
 *  - Some items have custom transforms to deal with NaN cases
 *  - Some items have double validation (ie images min and required),
 *      this covers cases such as undefined vs empty etc.
 */
export const PRODUCT_SCHEMA = yup.object({
  title: yup.string().required('title is required'),
  isActive: yup
    .boolean()
    .transform((value) => (value === null ? true : value))
    .required('active status must be set'),
  brand: yup.string().required('brand is required'),
  description: yup.string().required('description is required'),
  price: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .required('price is required'),
  salePrice: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .nullable(),
  quantity: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .nullable(),
  images: yup
    .array(
      yup.object({
        id: yup.string().required(),
        url: yup.string().required(),
      }),
    )
    .min(1, 'must include at least one image')
    .required('must include at least one image'),
  variants: yup.array(
    yup.object({
      title: yup
        .string()
        .transform((value) => (Number.isNaN(value) ? undefined : value))
        .required('variant title is required'),
      price: yup
        .number()
        .transform((value) => (Number.isNaN(value) ? undefined : value))
        .required('variant price is required'),
      salePrice: yup
        .number()
        .transform((value) => (Number.isNaN(value) ? undefined : value))
        .nullable(),
      quantity: yup
        .number()
        .transform((value) => (Number.isNaN(value) ? undefined : value))
        .nullable(),
      isTempId: yup.boolean().nullable(),
      id: yup.string(),
    }),
  ),
});
