import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useLocation, useHistory } from 'react-router-dom';

import { setMall } from '../../modules/mall/operations';

import { Sidebar } from 'components/sidebar';
import { appRoutes, sidebarNavigation } from '../../constants';
import { AuthRouter } from 'components/auth';
import { isAuthenticated, setMallId } from 'services/storage';
import PageWrapperComponent from './page-wrapper';
import {
  hostMallIdMap,
  mallData,
} from '../../constants/mall_info';

import './app.scss';

const unAuthedPaths = [
  '/google-auth',
  '/register',
  '/login',
  '/forgot-password',
  '/reset-password'
]

const App = () => {
  const isAuthed = isAuthenticated();
  const location = useLocation();
  const history = useHistory();
  const [mall, setMallData] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const mall_id = hostMallIdMap[window.location.hostname] || hostMallIdMap.stage;

    updateMallFavicon(mall_id);
    const mall = {
      mallData : mallData.filter((mall) => mall.mallId === mall_id)[0],
      mallId: mall_id
    }
    setMallData(mall); //set state
    setMallId(mall_id); // set local storage to mall id
    dispatch(setMall(mall)); // set redux to mall

    if (!isAuthed && unAuthedPaths.indexOf(location.pathname) === -1) {
      history.push('/login');
    }
  }, []);

  const updateMallFavicon = (mall_id) => {
    const faviconsList = ['favicon-apple-touch-icon', 'favicon-32x32', 'favicon-16x16', 'favicon-site', 'favicon'];
    faviconsList.forEach(favicon => {
      const faviconNode = document.getElementById(favicon);
      faviconNode.href = faviconNode.href.replace('/images/', `/images/${mall_id}/`);
    })
  }

  if (!isAuthed) {
    return <AuthRouter />;
  }
  return (
		<div className='app'>
			<Sidebar navigation={sidebarNavigation} mall={mall ? mall : {}} />
			<main className='app__main-content'>
				<Switch>
					{appRoutes.map(({ path, Component, props = { mall } }, i) => {
						return (
							<Route
								exact
								key={i}
								path={path}
								render={() => (<PageWrapperComponent Component={Component} {...props} />)}
							/>
						);
					})}
				</Switch>
			</main>
		</div>
	);
};

export default App;
