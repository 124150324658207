export default {
  fields: [
    [
      {
        label: 'Email',
        name: 'email',
        type: 'text'
      },
    ],
    [
      {
        label: 'Password',
        name: 'password',
        type: 'password'
      },
      {
        label: 'Confirm Password',
        name: 'passwordConfirm',
        type: 'password'
      }
    ],
    [
      {
        label: 'Store',
        name: 'store',
        type: 'text'
      }
    ],
    [
      {
        label: 'Center',
        name: 'centre',
        type: 'select',
        props: {
          options: [
            {
              label: 'Brazos Mall',
              value: 'brazos-mall'
            },
            {
              label: 'Connecticut Post Mall',
              value: 'connecticut-post-mall'
            },
            {
              label: 'Fox Valley Mall',
              value: 'foxvalley-mall'
            },
            {
              label: 'Hawthorn Mall',
              value: 'hawthorn-mall'
            },
            {
              label: 'Mainplace Mall',
              value: 'mainplace-mall'
            },
            {
              label: 'Pueblo Mall',
              value: 'pueblo-mall'
            },
            {
              label: 'Vancouver Mall',
              value: 'vancouver-mall'
            },
            {
              label: 'Dulles Town Center',
              value: 'dulles-town-center'
            },
          ],
          placeholder: 'Centre'
        }
      }
    ]
  ]
};
