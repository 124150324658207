import React, { useRef, useState } from 'react';
import { Button } from '@react-md/button';
import { productItemForm } from 'constants/forms';
import componentMap from "../../constants/form-component-map";
import {AmpedForm} from "amped-react-form-builder";
import { v4 as uuidv4 } from 'uuid';

import './product-variant.scss';

console.log('product form', productItemForm);
const defaultValues = { title: '', price: '', salePrice: '', quantity: ''};
const ProductVariants = ({
  name,
  value,
  onChange,
}) => {
  const addContainerRef = useRef();
  const [addValues, setAddValues] = useState(defaultValues);
  const variants = Array.isArray(value) ? value : [];
  const handleChange = (index, _, __, vals) => {
    const newValues = [ ...variants ];
    newValues[index] = vals;
    onChange(newValues);
  }
  const handleAdd = ({ title, price, salePrice, quantity}) => {
    onChange([
      ...value,
      {
        title,
        price: parseFloat(price),
        salePrice: parseFloat(salePrice),
        quantity: parseFloat(quantity),
        isTempId: true,
        id: uuidv4(),
      }
    ]);
    setAddValues(defaultValues);
    const titleField = addContainerRef.current.querySelector('#form-component-title');
    if (titleField) {
      titleField.focus();
    }
  }

  return (
    <div className="product-variant">
      {variants.map((variant, i) => {
        return (
          <div key={variant.id} className="product-variant__item">
            <AmpedForm
              ButtonComponent={null}
              formValues={variant}
              formData={productItemForm}
              componentMap={componentMap}
              onChange={handleChange.bind(this, i)}
            />
          </div>
        )
      })}
      {variants.length > 0 && <div className="product-variant__divider" />}
      <div className="product-variant__add" ref={addContainerRef}>
        <AmpedForm
          ButtonComponent={Button}
          buttonProps={{
            theme: 'secondary',
            themeType: 'contained'
          }}
          formValues={addValues}
          formData={productItemForm}
          componentMap={componentMap}
          onChange={(_, __, values) => setAddValues(values) }
          onSubmit={handleAdd}
        />
      </div>
    </div>
  );
};

export default ProductVariants;
