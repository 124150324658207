import classNames from 'classnames';
import React, { useEffect, useCallback } from 'react';
import { NewItem } from 'components/common';
import { FileInput } from '@react-md/form';
import {useDropzone} from 'react-dropzone'
import productImagesPlaceholder from '../../images/product-images-placeholder.png';
import { DeleteSVGIcon } from '@react-md/material-icons';
import { toastError, toastSuccess } from 'libs/toasts';

import './product-image-form.scss';
import {useMutation} from "@apollo/client";
import {UPLOAD_FILES} from "../../queries/file";

const ProductImageForm = ({
  onChange,
  maxImages = 4,
  value = [],
  ...props
}) => {
  const [uploadFiles, { data }] = useMutation(UPLOAD_FILES);
  const _value = Array.isArray(value) ? value : [];

  useEffect(() => {
    if (data && data.uploadFiles) {
      const newValue = _value.concat(data.uploadFiles);
      onChange(newValue);
      toastSuccess('Image uploaded')
    }
  }, [data]);

  const onDrop = useCallback((files) => {
    uploadFiles({
      variables: {
        files
      }
    }).then((res) => {
      return res
    }).catch((e) => {
      toastError(e.message)
      return e
    })
  })

  const  { getRootProps, getInputProps, isDragActive } = useDropzone({onDrop});
  const dropClasses = classNames('product-image-form__drop', {
    'product-image-form__drop--active': isDragActive
  })

  const placeholderDiff = maxImages - _value.length;
  const placholders = placeholderDiff > 0
    ? Array.from(Array(placeholderDiff).keys())
    : [];

  return (
    <div className="product-image-form">
      <div className={dropClasses}>
        <div className="product-image-form__drop__inner">
          Drop files here
        </div>
      </div>
      <div className="product-image-form__example">
        <div className="product-image-form__example__image">
          <img src={productImagesPlaceholder} alt=""/>
        </div>
        <div className="product-image-form__example__description">
          <p>Include pictures of your product with different details and angles, we recommend a white or neutral background.</p>
          <p>You can upload a maximum of 4 photos, that are at least 300px wide or tall (we recommend at least 1000px).</p>
          <a href={productImagesPlaceholder} rel="noreferrer" target="_blank">Download template image</a>
        </div>
      </div>
      <div className="product-image-form__images">
        <input type="file" className="product-image-form__images__drop" {...getInputProps()} />
        <div className="product-image-form__images__grid">
          {_value.map(({ url, id}, index) => {
            return (
              <div key={id} className="product-image-form__images__grid__item">
                <div className="product-image-form__images__grid__item__delete">
                  <div>
                    <DeleteSVGIcon onClick={() => {_value.splice(index, 1); onChange(_value)}} />
                  </div>
                </div>
                <img src={url} alt=""/>
              </div>
            )
          })}
          {placholders.map((val) => <NewItem key={val} getRootProps={getRootProps} /> )}
        </div>
        <div className="product-image-form__images__actions">
          <FileInput
            id="configurable-file-input"
            onChange={onChange}
            theme="secondary"
            themeType="contained"
            buttonType="text"
          >
            Upload
          </FileInput>
        </div>
      </div>
    </div>
  );
};

export default ProductImageForm;
