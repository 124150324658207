export default {
  fields: [
    [
      {
        label: 'Variant name',
        name: 'title',
        type: 'text'
      },
      {
        label: 'Price',
        name: 'price',
        type: 'price'
      },
      {
        label: 'Sale price',
        name: 'salePrice',
        type: 'price'
      },
      {
        label: 'Inventory',
        name: 'quantity',
        type: 'number'
      }
    ]
  ]
}
