import StorageMonitor from '../libs/storage-monitor';

//retailer-dashboard-*.dashboard.adeptmind.ai/
export const hostMallIdMap = {
  'retailer-dashboard-centennial.dashboard.adeptmind.ai': 'centennial-mall',
  'retailer-dashboard-radiant-partners.dashboard.adeptmind.ai': 'radiant-partners-mall',
  'retailer-dashboard-bayer-properties.dashboard.adeptmind.ai': 'bayer-properties-mall',
  'retailer-dashboard-oxford-properties.dashboard.adeptmind.ai': 'oxford-properties-mall',
  'retailer-dashboard-triple-five.dashboard.adeptmind.ai': 'triple-five-mall',
  'retailer-dashboard-first-capital.dashboard.adeptmind.ai': 'first-capital',
  'retailer-dashboard-quadreal.dashboard.adeptmind.ai': 'quadreal',
  'retailer-dashboard-demo.dashboard.adeptmind.ai': 'demo',

  'dashboard.plazaplus.plazalasamericas.com': 'plaza-ad-mall',
  'dashboard.plazaplus.plazadelcaribe.com': 'plaza-ad-mall',

  'retailer-dashboard-morguard.dashboard.adeptmind.ai': 'morguard-mall',

  'retailer-dashboard-centennial-stage.adeptmind.ai.s3-website-us-west-2.amazonaws.com': (() => {
    return localStorage.getItem(StorageMonitor.keys.MallId) || 'radiant-partners-mall';
  })(),
  'stage': (() => {
    return localStorage.getItem(StorageMonitor.keys.MallId) || 'radiant-partners-mall';
  })(),
};

export const mallData = [
  {
    mallId: 'centennial-mall',
    image: 'https://asset-files.mall.adeptmind.ai/dashboard-assets/shop_logos/logo.svg',
    mall: ['hawthorn-mall', 'vancouver-mall', 'pueblo-mall', 'mainplace-mall', 'foxvalley-mall', 'connecticut-post-mall', 'brazos-mall', 'dulles-town-center'],
  },
  {
    mallId: 'radiant-partners-mall',
    image: 'https://asset-files.mall.adeptmind.ai/dashboard-assets/shop_logos/radiant-partners.png',
    mall: ['alexandria-mall']
  },
  {
    mallId: 'bayer-properties-mall',
    image: 'https://asset-files.mall.adeptmind.ai/dashboard-assets/shop_logos/bayer-properties.png',
    mall: ['the-summit-birmingham-mall'],
  },
  {
    mallId: 'oxford-properties-mall',
    image: 'https://asset-files.mall.adeptmind.ai/dashboard-assets/shop_logos/oxford.png',
    mall: ['yorkdale-mall', 'square-one', 'upper-canada-mall', 'hillcrest-mall', 'kingsway-mall', 'southcentre-mall',
      'galeries-capitale', 'quartier-dix30', 'promenades-gatineau', 'scarborough-town-centre'],
  },
  {
    mallId: 'plaza-ad-mall',
    image: 'https://asset-files.mall.adeptmind.ai/dashboard-assets/shop_logos/plaza-logo.png',
    mall: ['plaza-las-americas', 'plaza-del-caribe'],
  },
  {
    mallId: 'triple-five-mall',
    image: 'https://asset-files.mall.adeptmind.ai/dashboard-assets/shop_logos/triple-five-seeklogo.com.svg',
    mall: ['mall-of-america'],
  },
  {
    mallId: 'first-capital',
    image: 'https://asset-files.mall.adeptmind.ai/dashboard-assets/shop_logos/first-capital-logo.com.png',
    mall: ['yorkville-village'],
  },
  {
    mallId: 'quadreal',
    image: 'https://asset-files.mall.adeptmind.ai/dashboard-assets/shop_logos/quadreal.png',
    mall: ['willowbrook'],
  },
  {
    mallId: 'demo',
    image: 'https://asset-files.mall.adeptmind.ai/dashboard-assets/shop_logos/adeptmind.png',
    mall: ['demo'],
  },
  {
    mallId: 'morguard-mall',
    image: 'https://asset-files.mall.adeptmind.ai/dashboard-assets/shop_logos/morguard.png',
    mall: ['bramalea-city-centre', 'coquitlam-centre'],
  }
]

// export const productionMallDomains = [
// ];

// export const inventoryMallIdMap = {
// };
