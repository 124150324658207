import DashboardPage from 'pages/dashboard';
import Products from 'pages/products';
import Product from 'pages/product';
import Store from 'pages/store';
import Stores from 'pages/stores';
import { UserProfilePage } from 'pages/user';

export default [
  {
    path: '/',
    Component: DashboardPage,
  },
  {
    path: '/stores',
    Component: Stores,
  },
  {
    path: '/store/:storeId',
    Component: Store,
  },
  {
    path: '/products',
    Component: Products,
  },
  {
    path: '/store/:storeId/products',
    Component: Products,
  },
  {
    path: '/product',
    Component: Product,
  },
  {
    path: '/store/:storeId/product/:productId',
    Component: Product,
  },
  {
    path: '/user/profile',
    Component: UserProfilePage,
  },
];
