import {toast} from "react-toastify";

const defaultOptions = {
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
}

export const toastSuccess = (content, options = {}) => {
  toast.success(content, {
    ...defaultOptions,
    ...options
  });
}

export const toastError = (content, options = {}) => {
  toast.error(content, {
    ...defaultOptions,
    ...options,
  });
}

export const toastWarning = (content, options = {}) => {
  toast.warn(content, {
    ...defaultOptions,
    ...options,
  });
}
