import componentMap from 'constants/form-component-map';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AmpedForm } from 'amped-react-form-builder';
import { Button } from 'react-md';
import { Loader, PageActions, Paper } from 'components/common';
import { toastError, toastSuccess, toastWarning } from 'libs/toasts';
import { cleanApiInput } from 'libs/api';
import { useMutation, useQuery } from '@apollo/client';

import './amped-graph-form.scss';

const getQueryData = (data) => {
  if (!data) {
    return {};
  }
  const keys = Object.keys(data);
  return data[keys[0]];
};

const AmpedGraphForm = ({
  apolloProps = {},
  createMessage = 'Create',
  dataQuery = null,
  dataQueryVariables = {},
  displayButton = false,
  displayToast = true,
  initialFormValues = {},
  isCreating = false,
  itemName = '',
  redirectPath = '',
  showActions = true,
  showSubtitle = true,
  updateQuery = null,
  updateQueryVariables = {},
  onComplete = () => {},
  onSubmit = () => {},
  schema = null,
  ...rest
}) => {
  if (!dataQuery || !updateQuery) {
    throw new Error('dataQuery and updateQuery are required');
  }

  const history = useHistory();
  const [formValues, setFormValues] = useState(initialFormValues);
  const { loading: queryLoading, data: values } = useQuery(dataQuery, {
    variables: dataQueryVariables,
    fetchPolicy: 'no-cache',
  });
  const [saveData, { error }] = useMutation(updateQuery, updateQueryVariables);

  if (error) {
    toastError(error.message);
  }

  const handleSubmit = async () => {
    const formData = cleanApiInput({
      ...getQueryData(values),
      ...formValues,
    });

    let inputData = formData;

    if (schema) {
      try {
        inputData = await schema.validate(formData, {
          abortEarly: false,
        });
      } catch (validationError) {
        const message = validationError.errors.join(', ');

        if (isCreating) {
          toastError(`Error: ${message}`);
          return;
        }
        // Updates should not abort early, since there may be 'sparse' data in the db as is
        else {
          toastWarning(`Warning: ${message}`);
        }
      }
    }

    const savedData = await saveData({
      variables: {
        input: inputData,
        ...updateQueryVariables,
      },
    });

    const newProductId = getQueryData(savedData.data).id;
    history.replace(`${redirectPath}/${newProductId}`);

    toastSuccess(`${itemName} ${isCreating ? 'created' : 'updated'}`);
  };

  const handleChange = (name, value) => {
    const newValues = {
      ...getQueryData(values),
      ...formValues,
      [name]: value,
    };
    setFormValues(newValues);
  };

  const isExisting = values;
  const data = {
    ...getQueryData(values),
    ...formValues,
  };
  const title = isExisting ? data && (data.title || data.name) : createMessage;
  const subTitle = isExisting
    ? `You are editing this ${itemName.toLowerCase()}`
    : `Add a new ${itemName.toLowerCase()} to your account`;
  const saveButtonLabel = isExisting ? 'Save' : 'Create';

  return (
    <div className="amped-graph-query">
      <Loader visible={queryLoading} />
      {!queryLoading && (
        <div className="amped-graph-query__container">
          {showActions && (
            <PageActions
              Actions={() => [
                <Button
                  key="save-btn"
                  theme="primary"
                  themeType="contained"
                  onClick={handleSubmit}
                >
                  {saveButtonLabel}
                </Button>,
              ]}
              subTitle={showSubtitle && subTitle}
              title={title}
            />
          )}
          <div className="amped-graph-query__inner">
            <Paper>
              <AmpedForm
                ButtonComponent={(props) => (displayButton ? <Button /> : null)}
                formValues={data}
                componentMap={componentMap}
                {...rest}
                onChange={handleChange}
                onSubmit={onSubmit || handleSubmit}
              />
            </Paper>
          </div>
        </div>
      )}
    </div>
  );
};

AmpedGraphForm.propTypes = {
  apolloProps: PropTypes.object,
  dataQuery: PropTypes.object,
  dataQueryVariables: PropTypes.shape(),
  displayButton: PropTypes.bool,
  displayToast: PropTypes.bool,
  isCreating: PropTypes.bool,
  itemName: PropTypes.string,
  showActions: PropTypes.bool,
  updateQuery: PropTypes.object,
  updateQueryVariables: PropTypes.shape(),
  onComplete: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default AmpedGraphForm;
