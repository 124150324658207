import React from 'react';
import gql from "graphql-tag";
import { useQuery } from '@apollo/client';
import { HomeSVGIcon, LabelSVGIcon, InfoOutlineSVGIcon } from '@react-md/material-icons';

import { SideBarList } from './sidebar';

export const GET_MY_STORES = gql`
    query {
      myStores {
        id
        name
      }
    }
`;

const SidebarStoresComponent = ({
  currentPath
}) => {

  const { data: stores } = useQuery(GET_MY_STORES);

  if (!stores) {
    return null;
  }

  const links = stores.myStores.map(({ id, name }) => {
    return {
      label: name,
      link: `/store/${id}/products`,
      icon: HomeSVGIcon,
      children: [
        {
          label: 'Products',
          link: `/store/${id}/products`,
          matchPattern: `^/store/${id}/product(/[0-9]+|)$`,
          icon: LabelSVGIcon
        },
        {
          label: 'Store Info',
          link: `/store/${id}`,
          icon: InfoOutlineSVGIcon
        },
      ]
    }
  });

  return (
    <div className="sidebar-stores">
      {stores.myStores.map(({ id, name }) => {
        return (
          <div className="sidebar__list">
            <SideBarList
              currentPath={currentPath}
              links={links}

            />

          </div>
        )
      })}
    </div>
  );
};

export default SidebarStoresComponent;
