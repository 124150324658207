const SET_MALL = 'app/mall/SET_MALL';

const setMall = (payload) => ({
	type: SET_MALL,
	payload,
});

export const types = {
	SET_MALL,
};

export const actions = {
	setMall,
};

export default {
	types,
	actions,
};
