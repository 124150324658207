import React from 'react';
import newProductPlaceholder from "../../images/new-product-placeholder.svg";
import {AddSVGIcon} from "@react-md/material-icons";

import './new-item.scss';

const NewItem = ({
 onClick = () => {},
 getRootProps = () => {},
}) => {
  return (
    <div className="new-item" {...getRootProps()} >
      <img src={newProductPlaceholder} alt=""/>
      <div className="new-item__add-icon">
        <AddSVGIcon />
      </div>
    </div>
  );
};

export default NewItem;
