import React from 'react';
import { Switch as SwitchMd } from '@react-md/form';

import './switch.scss';

const Switch = ({
  checkedValue = true,
  uncheckedValue = false,
  value,
  onChange,
  ...props
}) => {

  const handleChange = (evt) => {
    onChange( evt.target.checked ? checkedValue : uncheckedValue )
  }

  return (
    <div className="switch-form">
      <SwitchMd
        {...props}
        defaultChecked={value === checkedValue || (typeof value === 'string' && value)}
        onChange={handleChange}
      />
    </div>
  );
};

export default Switch;
