import React from 'react';
import { AmpedGraphForm } from 'components/form';
import { useRouteMatch } from 'react-router-dom';
import { storeForm } from 'constants/forms';
import gql from 'graphql-tag';

export const STORE = gql`
    query GetStore($id: ID!) {
        getStore(id: $id) {
            id
            name
            description
            logo {
                id,
                url
            }
            location
            website
            phone
            email
        }
    }
`;

export const UPDATE_STORE = gql`
    mutation UpdateStore($id: ID!, $input: StoreInput!) {
        updateStore(id: $id, input: $input) {
            id
        }
    }
`;

export const CREATE_STORE = gql`
    mutation CreateStore($input: StoreInput!) {
        createStore(input: $input) {
            id
        }
    }
`;

const StorePage = () => {

  const match = useRouteMatch();

  const isCreating = !match.params.storeId

  const updateQuery = isCreating ? CREATE_STORE : UPDATE_STORE;
  const dataQueryVariables = isCreating ? {} : {
    id: match.params.storeId
  }

  return (
    <AmpedGraphForm
      dataQuery={STORE}
      dataQueryVariables={dataQueryVariables}
      formData={storeForm}
      isCreating={isCreating}
      itemName="Store"
      updateQuery={updateQuery}
      updateQueryVariables={dataQueryVariables}
    />
  );
};

export default StorePage;

