import { types } from './actions';
import { getStorage, STORAGE_KEYS } from '../../services/storage';

const initialState = getStorage(STORAGE_KEYS.MALL) || {
	mall: {},
	mallId: '',
};

const mallReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.SET_MALL:
			return action.payload;

		default:
			return state;
	}
};

export default mallReducer;
