export default {
  fields: [
    [
      {
        label: 'Name',
        name: 'name',
        type: 'text'
      },
    ],
    [
      {
        label: 'Description',
        name: 'description',
        type: 'textarea'
      }
    ],
    [
      {
        label: 'Logo',
        name: 'logo',
        type: 'file'
      },
      {
        label: 'Location',
        name: 'location',
        type: 'text'
      }
    ],
    [
      {
        label: 'Email',
        name: 'email',
        type: 'text'
      },
      {
        label: 'Phone',
        name: 'phone',
        type: 'text'
      }
    ],
    [
      {
        label: 'Website',
        name: 'website',
        type: 'text'
      }
    ],
  ]
};
