import PropTypes from 'prop-types';
import React from 'react';
import ProductListItem from './list-item';
import ProductListItemNew from './list-item-new';

import './list.scss';

const ProductList = ({
  products,
}) => {
  return (
    <div className="product-list">
      <div className="product-list__inner">
        <div className="product-list__grid">
          {products.map((product) => {
            return (
              <ProductListItem {...product} />
            )
          })}
          <ProductListItemNew />
        </div>
      </div>
    </div>
  );
};

ProductList.propTypes = {
  products: PropTypes.array,
}

export default ProductList;
