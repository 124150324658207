export default {
  fields: [
    [
      {
        label: 'Name',
        name: 'name',
        type: 'text',
      },
    ],
    [
      {
        label: 'Email',
        name: 'email',
        type: 'text',
      },
    ]
  ]
}
