import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { NewItem } from 'components/common';

import './list-item.scss';

const ProductListItemNew = () => {
  return (
    <Link to="/product" className="product-list-item product-list-item--add-new">
      <div className="product-list-item__image">
        <NewItem />
      </div>
      <div className="product-list-item__title">
        Add new product
      </div>
    </Link>
  );
};

ProductListItemNew.propTypes = {
  images: PropTypes.array,
  price: PropTypes.string,
  salePrice: PropTypes.string,
  status: PropTypes.string,
  title: PropTypes.string,
}

export default ProductListItemNew;
