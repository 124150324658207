export default {
  fields: [
    [
      {
        label: 'Details',
        type: 'header',
        props: {
          offsetTop: true,
        }
      }
    ],
    [
      {
        label: 'Title',
        name: 'title',
        type: 'text',
        props: {
          style: {
            width: '150%',
          }
        }
      },
      {
        label: 'Active',
        name: 'isActive',
        type: 'switch',
        props: {
          style: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: 30,
          }
        }
      }
    ],
    [
      {
        label: 'Brand',
        name: 'brand',
        type: 'text',
        props: {
          style: {
            width: '100%',
          }
        }
      },
    ],
    [
      {
        label: 'Description',
        name: 'description',
        type: 'textarea'
      }
    ],
    [
      {
        label: 'Price',
        name: 'price',
        type: 'price'
      },
      {
        label: 'Sale price',
        name: 'salePrice',
        type: 'price'
      },
      {
        label: 'Inventory',
        name: 'quantity',
        type: 'number'
      }
    ],
    [
      {
        label: 'Attributes',
        name: 'attributes',
        type: 'attributeSelector',
        props: {
          placeholder: 'Start typing...'
        }
      },
      {
        label: `Add additional information about the product to help the search accuracy<br/>
        <strong>"[attribute&nbsp;name]&nbsp;-&nbsp;[attribute&nbsp;value]"</strong> <strong>For example</strong> "Collar Type - V-Neck"`,
        type: 'description',
        props: {
          style: {
            display: 'flex',
            height: '100%',
            alignItems: 'flex-end',
          }
        }
      }
    ],
    [
      {
        label: 'Variants',
        type: 'header'
      }
    ],
    [
      {
        label: 'If this product has multiple sizes or quantities, enter those details below',
        type: 'description'
      }
    ],
    [
      {
        label: 'Variants',
        name: 'variants',
        type: 'productVariants',
        info: ''
      }
    ],
    [
      {
        label: 'Images',
        type: 'header'
      }
    ],
    [
      {
        label: 'Images',
        name: 'images',
        type: 'productImages',
        info: ''
      }
    ],
  ]
};
