import React, {useEffect} from 'react';
import gql from "graphql-tag";
import { Button } from '@react-md/button';
import { TextIconSpacing } from '@react-md/icon';
import { AddSVGIcon } from '@react-md/material-icons';
import { useQuery } from '@apollo/client';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { PageActions } from 'components/common';
import { Loader } from 'components/common';
import { ProductList } from 'components/products';

export const GET_STORE = gql`
    query GetStore($id: ID!) {
        getStore(id: $id) {
            id
            name
            logo {
                url
            }
            products {
              id
              isActive
              title
              price
              salePrice
              storeId
              images {
                url
              }
            }
        }
    }
`;

const ProductsPage = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const { loading, data, refetch } = useQuery(GET_STORE, {
    variables: {
      id: match.params.storeId
    }
  });

  useEffect(() => {
    refetch()
  }, [])

  if (loading) {
    return <Loader />
  }

  const {
    logo,
    name,
    products = [],
  } = (data && data.getStore) || {};

  const {
    url: logoUrl
  } = logo || {};

  const subTitle = `${products.length} Product${products.length === 1 ? '' : 's'}`;

  return (
    <div className="products-page">
      <PageActions
        Actions={() => (
          <Button theme="warning" themeType="contained" onClick={() => history.push('/product') }>
            <TextIconSpacing icon={<AddSVGIcon />}>Add Product</TextIconSpacing>
          </Button>
        )}
        Image={() => <img src={logoUrl} alt=""/> }
        title={name}
        subTitle={subTitle}
      />
      <div className="products-page__content">
        <ProductList
          products={products}
        />
      </div>
    </div>
  );
};

export default ProductsPage;
