import gql from "graphql-tag";

export const UPLOAD_FILE = gql`
    mutation UploadFile($file: Upload!) {
        uploadFile(file: $file) {
            id
            url
        }
    }
`;

export const UPLOAD_FILES = gql`
    mutation UploadFiles($files: [Upload]!) {
        uploadFiles(files: $files) {
            id
            url
        }
    }
`;
