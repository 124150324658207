import React from 'react';
import gql from "graphql-tag";
import { Button } from '@react-md/button';
import { TextIconSpacing } from '@react-md/icon';
import { AddSVGIcon } from '@react-md/material-icons';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { PageActions } from 'components/common';
import { Loader } from 'components/common';
import { ProductList } from 'components/products';

const GET_STORE = gql`
  query {
    getMyStore {
      id
      name
      logo {
        url
      }
      products {
        id
        isActive
        title
        price
        salePrice
        storeId
        images {
          url
        }
      }
    }
  }
`;

const DashboardPage = () => {
  const history = useHistory();
  const { loading, data } = useQuery(GET_STORE);

  if (loading) {
    return <Loader />
  }

  const {
    logo,
    name,
    products = [],
  } = (data && data.getMyStore) || {};

  const {
    url: logoUrl
  } = logo || {};

  const subTitle = `${products.length} Product${products.length === 1 ? '' : 's'}`;

  return (
    <div className="products-page">
      <PageActions
        Actions={() => (
          <Button theme="warning" themeType="contained" onClick={() => history.push('/product') }>
            <TextIconSpacing icon={<AddSVGIcon />}>Add Product</TextIconSpacing>
          </Button>
        )}
        Image={() => <img src={logoUrl} alt=""/> }
        title={name}
        subTitle={subTitle}
      />
      <div className="products-page__content">
        <ProductList
          products={products}
        />
      </div>
    </div>
  );
};

export default DashboardPage;
