import React from 'react';

import './profile.scss';
import {AmpedGraphForm} from "components/form";
import { userProfileForm } from 'constants/forms';
import gql from "graphql-tag";

const ME = gql`
  query {
    me {
      email
      name
      activeStoreId
    }
  }
`;

const UPDATE_ME = gql`
    mutation UpdateMe($input: UserInput!) {
        updateMe(input: $input) {
            id
        }
    }
`

const UserProfile = () => {
  return (
    <div className="user-profile-page">
      <AmpedGraphForm
        dataQuery={ME}
        formData={userProfileForm}
        isCreating={false}
        itemName="User"
        updateQuery={UPDATE_ME}
      />
    </div>
  );
};

export default UserProfile;
