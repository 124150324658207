import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { AmpedGraphForm } from 'components/form';
import { productForm } from 'constants/forms';
import gql from 'graphql-tag';
import { useMe } from 'hooks';
import { PRODUCT_SCHEMA } from './validators';
import './product-page.scss';

export const PRODUCT = gql`
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      title
      brand
      description
      isActive
      price
      salePrice
      quantity
      images {
        id
        url
      }
      attributes {
        id
        label
        name
        value
      }
      variants {
        id
        title
        price
        salePrice
        quantity
      }
    }
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation UpdateProduct($id: ID!, $input: ProductInput!) {
    updateProduct(id: $id, input: $input) {
      id
    }
  }
`;

export const CREATE_PRODUCT = gql`
  mutation CreateProduct($input: ProductInput!) {
    createProduct(input: $input) {
      id
    }
  }
`;

const ProductPage = () => {
  const match = useRouteMatch();
  const { me } = useMe();

  const isCreating = !match.params.productId;

  const updateQuery = isCreating ? CREATE_PRODUCT : UPDATE_PRODUCT;

  const dataQueryVariables = isCreating ? {} : { id: match.params.productId };

  const formValues = me ? { storeId: me.activeStoreId } : {};

  return (
    <div className="single-product-page">
      <div className="single-product-page__content">
        <AmpedGraphForm
          createMessage="Add a new product"
          dataQuery={PRODUCT}
          dataQueryVariables={dataQueryVariables}
          formData={productForm}
          initialFormproductIncludesValues={formValues}
          isCreating={isCreating}
          itemName="Product"
          redirectPath={`/store/${me && me.activeStoreId}/product`}
          updateQuery={updateQuery}
          updateQueryVariables={dataQueryVariables}
          schema={PRODUCT_SCHEMA}
          initialFormValues={isCreating ? { isActive: true } : {}}
        />
      </div>
    </div>
  );
};

export default ProductPage;
