import classNames from 'classnames';
import React from 'react';
import './header.scss';

const Header = ({
  label,
  offsetTop = false,
}) => {
  const classes = classNames('form-header', {
    'form-header--top-offset': offsetTop,
  })
  return (
    <h2 className={classes}>
      {label}
    </h2>
  );
};

export default Header;
