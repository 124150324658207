import {
  DashboardSVGIcon
} from "@react-md/material-icons";
import { SidebarStoresComponent } from 'components/sidebar';
export default [
  {
    label: 'Dashboard',
    link: '/',
    icon: DashboardSVGIcon,
  },
  {
    Component: SidebarStoresComponent,
  },
];
