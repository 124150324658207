class StorageMonitor {
	constructor() {
		this.keys = {
			MallId: 'adept-mall-id',
		};
	}

	getItem(key, defaultValue = '') {
		try {
			return JSON.parse(window.localStorage.getItem(key));
		} catch (e) {
			return window.localStorage.getItem(key) || defaultValue;
		}
	}

	removeItem(key) {
		window.localStorage.removeItem(key);
	}

	setItem(key, value) {
		const _value = typeof value !== 'string' ? JSON.stringify(value) : value;

		window.localStorage.setItem(key, _value);
		([]).forEach((callback) => {
			const stored = window.localStorage.getItem(key);

			try {
				callback(JSON.parse(stored));
			} catch (error) {
				callback(stored);
			}
		});
	}
}

export default new StorageMonitor();
