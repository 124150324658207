import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import newProductPlaceholder from 'images/new-product-placeholder.svg';

import './list-item.scss';

const ProductListItem = ({
  id,
  images,
  isActive,
  price,
  salePrice,
  storeId,
  title,
}) => {
  const isOnSale = salePrice && price - salePrice > 0;
  const priceClasses = classNames('product-list-item__price__regular', {
    'product-list-item__price__regular--on-sale': isOnSale,
  });
  const activePillClasses = classNames('product-list-item__active-pill', {
    'product-list-item__active-pill--inactive': !isActive,
  })
  return (
    <Link to={`/store/${storeId}/product/${id}`} className="product-list-item">
      <div className="product-list-item__actions">
        <div className={activePillClasses}>
          {isActive ? 'Active' : 'Inactive'}
        </div>
      </div>
      <div className="product-list-item__image">
        <img src={(images && images[0] && images[0].url) || newProductPlaceholder} alt=""/>
      </div>
      <h3 className="product-list-item__title">
        {title}
      </h3>
      <div className="product-list-item__price">
        {isOnSale && (
          <div className="product-list-item__price__sale">
            ${salePrice.toFixed(2)}
          </div>
        )}
        {price && (
          <div className={priceClasses}>
            ${price.toFixed(2)}
          </div>
        )}
      </div>
    </Link>
  );
};

ProductListItem.propTypes = {
  images: PropTypes.array,
  price: PropTypes.number,
  salePrice: PropTypes.number,
  title: PropTypes.string,
}

export default ProductListItem;
