export const linkMatchesPath = ({ currentPath, link, matchPattern }) => {

  console.log('MATCH', currentPath, matchPattern);

  if (matchPattern) {
    const match = currentPath.match(new RegExp(matchPattern));
    if (match && match[0] && match[0] === currentPath) {
      return true;
    }
  }

  return currentPath === link;
}

export const isActive = ({
  currentPath,
  links
}) => {
  const _links = Array.isArray(links)
    ? links.slice(0)
    : [links];
  const recurv = ({ links }) => {
    return links.filter(({ link, children, matchPattern }) => {
      return linkMatchesPath({ currentPath, link, matchPattern }) || (children && recurv({ links: children }).length > 0)
    })
  };

  return recurv({ links: _links }).length > 0
}
