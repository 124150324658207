import React, { useEffect } from 'react';
import { AutoComplete } from "@react-md/autocomplete";
import { Chip } from "@react-md/chip";
import { AddCircleSVGIcon } from "@react-md/material-icons";
import { useMutation, useQuery } from '@apollo/client';
import gql from "graphql-tag";

import './attribute-selector.scss';

export const GET_MY_ATTRIBUTES = gql`
    query {
        getMyAttributes {
            id
            label
            name
            value
        }
    }
`;

export const CREATE_ATTRIBUTE = gql`
    mutation CreateAttribute($name: String!, $value: String!) {
        createAttribute(name: $name, value: $value) {
            id
            label
            name
            value
        }
    }
`;

const XCircleIcon = (props) => (
  <AddCircleSVGIcon
    {...props}
    style={{
      transform: "rotate(45deg)",
      WebkitTransform: "rotate(45deg)",
    }}
  />
);

const AttributeSelector = ({
  label,
  name,
  placeholder = '',
  value = [],
  onChange
}) => {
  const { data: nameData } = useQuery(GET_MY_ATTRIBUTES);
  const [addAttribute, { data: attributeData}] = useMutation(CREATE_ATTRIBUTE);

  const handleAutocomplete = ({ result }) => {
    const _value = Array.isArray(value) ? value : [];
    const {
      __typename,
      ...newAttribute
    } = result;
    onChange([
      ..._value,
      newAttribute,
    ])
  }

  const onFilter = (strFind) => {
    return nameData.getMyAttributes.filter(({ label }) => label.toLowerCase().indexOf(strFind.toLowerCase()) > -1 );
  }

  const handleKeyDown = async (evt) => {
    if (evt.which === 13) {
      const elem = evt.target;
      const [name, value] = elem.value.split('-');
      setTimeout(() => {
        elem.value = '';
      })
      await addAttribute({
        variables: {
          name,
          value,
        }
      })
    }
  }

  const handleRemove = ({name: removeName, value: removeValue}) => {
    onChange(value.filter(({name, value}) => name !== removeName && value !== removeValue ));
  }

  useEffect(() => {
    if (attributeData) {
      handleAutocomplete({
        result: attributeData.createAttribute,
      });
    }
  }, [attributeData]);

  if (!nameData) {
    return null;
  }

  return (
    <div className="attribute-selector">
      <div className="attribute-selector__label">
        {label}
      </div>
      {value && (
        <div className="attribute-selector__results">
          {value.filter(Boolean).map(({name, value}) => (
            <Chip key={`${name}-${value}`} rightIcon={<XCircleIcon onClick={() => handleRemove({name, value}) } />}>
              {name} - {value}
            </Chip>
          ))}
        </div>
      )}
      <div className="attribute-selector__add-new">
        <AutoComplete
          clearOnAutoComplete
          data={nameData.getMyAttributes}
          filter={onFilter}
          highlight
          id={name}
          label={false}
          labelKey="label"
          placeholder={placeholder}
          valueKey="name"
          onKeyDown={handleKeyDown}
          onAutoComplete={handleAutocomplete}
        />
      </div>
    </div>
  );
};

export default AttributeSelector;
