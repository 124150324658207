import React from 'react';
import {TextField} from "@react-md/form";

import './price.scss';

const PriceTextField = ({
  label,
  name,
  onChange,
  ...props
}) => (
  <div className="price-field">
    <label htmlFor={name} className="price-field__label">
      {label}
    </label>
    <div className="price-field__container">
      <div className="price-field__dollar-sign">
        $
      </div>
      <TextField
        {...props}
        name={name}
        type="number"
        onChange={(evt) => onChange(parseFloat(evt.target.value)) }
      />
    </div>
  </div>
);

export default PriceTextField;
