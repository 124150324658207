import { actions } from './actions';

export const setMall = (mall) => (
	dispatch,
	getState
) => {
	const curr = updateMall(mall);
	curr(dispatch, getState);
};

export const updateMall = (payload) => (dispatch) => {
	// setStorage(STORAGE_KEYS.MALL, JSON.stringify(payload));
	dispatch(actions.setMall(payload));
};

export default {
	setMall,
};
