import React from 'react';
import AttributeSelector from 'components/form/attribute-selector';
import Description from 'components/form/description';
import FormFile from 'components/form/file';
import Header from 'components/form/header';
import PriceTextField from 'components/form/price';
import ProductImages from 'components/form/product-image-form';
import ProductVariants from 'components/form/product-variants';
import Select from 'components/form/select';
import Switch from 'components/form/switch';
import Textarea from 'components/form/textarea';
import TextField from 'components/form/textfield';

export default {
  attributeSelector: {
    component: AttributeSelector,
  },
  description: {
    component: Description
  },
  brand: {
    component: TextField
  },
  file: {
    component: FormFile,
  },
  header: {
    component: Header,
  },
  number: {
    component: (props) => <TextField type="number" {...props} /> ,
  },
  password: {
    component: (props) => <TextField type="password" {...props} /> ,
  },
  price: {
    component: PriceTextField
  },
  productImages: {
    component: ProductImages
  },
  productVariants: {
    component: ProductVariants,
  },
  select: {
    component: Select,
  },
  switch: {
    component: Switch,
  },
  text: {
    component: TextField,
  },
  textarea: {
    component: Textarea,
  },
};
