import React from 'react';

import './description-form.scss';

const Description = ({
  label,
  style = {},
}) => (
  <div className="description-form" style={style}>
    <div className="description-form__content" dangerouslySetInnerHTML={{ __html: label}} />
  </div>
);

export default Description;
