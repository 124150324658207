import React from 'react';
import {TextField as MDTextfield} from "@react-md/form";

import './textfield.scss';

const Textfield = ({
  label,
  name,
  type = 'text',
  onChange,
  ...props
}) => (
  <div className="text-form-field">
    <label className="text-form-field__label" htmlFor={name}>
      {label}
    </label>
    <MDTextfield
      {...props}
      type={type}
      onChange={(evt) => onChange(type === 'number' ? parseFloat(evt.target.value) : evt.target.value)  }
    />
  </div>
);

export default Textfield;
