import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button } from "@react-md/button";
import { REGISTER_USER } from '../../queries/auth';
import { setUserToken } from '../../services/storage';
import { useMutation } from "@apollo/client";
import { cleanErrorMessage } from 'libs/errors';
import componentMap from "../../constants/form-component-map";
import {AmpedForm} from "amped-react-form-builder";
import { registerForm } from 'constants/forms';
import './login.scss';

const AuthLogin = () => {
  const [register, { data: token, error: errorMessage, loading }] = useMutation(REGISTER_USER);
  const mall = useSelector((state) => state.mall);

  if (token) {
    setUserToken(token.register.token);
    window.location.href = '/';
    return;
  }

  const doRegister = (input) => {
    register({
      variables: {
        input
      }
    })
  };

  // Change centre options
  const updateCenterOptions = registerForm => {
   const centerOptionsRegisterForm = registerForm.fields.reduce((acc,curr) => {
     if(curr[0].name === 'centre' && mall.mallData) {
       curr[0].props.options = mall.mallData.mall.reduce((options, value) => {
        const option = { label: value.split('-').map(item => item.replace(/^(.)|\s+(.)/g, c => c.toUpperCase())).join(' '), value: value }; 
        return [...options, option]
       }, [])
     }
     return [...acc, curr];
   },[]);
    return {...registerForm, centerOptionsRegisterForm}
  }

  return (
    <div className="auth auth--register">
      <div className="auth__form__content">
        <div className="auth__form__logo">
					{mall.mallData && <img src={(mall.mallData.image)} alt={mall.mallData.mallId} />}
        </div>
        <h2 className="auth__title">Register</h2>
      </div>
      {errorMessage && (
        <div className="auth__error">
          {cleanErrorMessage({ error: errorMessage})}
        </div>
      )}
      <div className="auth__fields">
        <AmpedForm
          ButtonComponent={({ onClick }) => (
            <Button
              disabled={loading}
              theme="primary"
              themeType="contained"
              onClick={onClick}
            >
              Create Account
            </Button>
          )}
          componentMap={componentMap}
          formData={updateCenterOptions(registerForm)}
          onSubmit={doRegister}
        />
      </div>

      <div className="auth__register">
        Already have an account?&nbsp;<a href="/login">Login</a>
      </div>
    </div>
  );
}

export default withRouter(AuthLogin);

