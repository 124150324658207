import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button } from "@react-md/button";
import {
  TextField,
} from 'react-md';

import { LOGIN } from '../../queries/auth';
import { setUserToken } from '../../services/storage';
import { useMutation } from "@apollo/client";
import { cleanErrorMessage } from 'libs/errors';

const AuthLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [login, { data: token, error: errorMessage }] = useMutation(LOGIN);
  const mall = useSelector(state => state.mall);

  const doLogin = () => {
    login({
      variables: {
        email,
        password
      }
    })
  };

  if (token) {
    setUserToken(token.signIn.token);
    window.location.href = '/';
    return;
  }

  const handleKeyDown = (evt) => {
    if (evt.which === 13) {
      doLogin();
    }
  };

  return (
		<div className='auth'>
			<div className='auth__form__content'>
				<div className='auth__form__logo'>
					{mall.mallData && <img src={(mall.mallData.image)} alt={mall.mallData.mallId} />}
				</div>
				<h2 className='auth__title'>Login</h2>
			</div>
			{errorMessage && (
				<div className='auth__error'>
					{cleanErrorMessage({ error: errorMessage })}
				</div>
			)}
			<div className='auth__fields'>
				<div className='auth__field'>
					<TextField
						id='email'
						label='Email'
						value={email}
						onChange={(evt) => setEmail(evt.target.value)}
						onKeyDown={handleKeyDown}
					/>
				</div>
				<div className='auth__field'>
					<TextField
						id='password'
						label='Password'
						type='password'
						value={password}
						onChange={(evt) => setPassword(evt.target.value)}
						onKeyDown={handleKeyDown}
					/>
				</div>
			</div>

			<div className='auth__actions'>
				<Button theme='primary' themeType='contained' onClick={doLogin}>
					Login
				</Button>
			</div>

			<div className='auth__register'>
				<a href='/register'>Create an account</a>
				<a href='/forgot-password'>Forgot password</a>
			</div>
		</div>
	);
}

export default withRouter(AuthLogin);
